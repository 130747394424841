import "core-js/modules/es.array.reduce.js";
import { post } from '@/utils/request';
import { add, subtract, multiply } from 'lodash';
import TableForm from '@/components/TableForm/index.vue';
export default {
  components: {
    TableForm
  },
  props: {},
  data() {
    return {
      readonly: false,
      title: '',
      action: '',
      form: {},
      tableFormColumns: [{
        prop: 'type',
        show: false,
        defaultValue: 1
      }, {
        prop: 'code',
        label: '存货编号',
        width: 140,
        formType: 'select',
        options: '/DeviceModelSetting/select',
        methods: {
          change: (v, row) => {
            row.product_name = '';
            row.device_model_id = '';
            row.unit = '';
            if (!v) return;
            const current = this.$refs.tableFormRef.options.code.find(({
              value
            }) => value === v);
            if (!current) return;
            row.product_name = current.short;
            row.device_model_id = current.id;
            row.unit = current.unit;
          }
        }
      }, {
        prop: 'product_name',
        label: '简写',
        width: 140,
        formType: 'select',
        options: async () => (await post('/DeviceModelSetting/select')).data.list.map(item => {
          item.code = item.value;
          item.label = item.value = item.short;
          return item;
        }),
        methods: {
          change: (v, row) => {
            row.code = '';
            row.device_model_id = '';
            row.unit = '';
            if (!v) return;
            const current = this.$refs.tableFormRef.options.product_name.find(({
              value
            }) => value === v);
            if (!current) return;
            row.code = current.code;
            row.device_model_id = current.id;
            row.unit = current.unit;
          }
        }
      }, {
        prop: 'store_id',
        label: '收货仓库',
        width: 140,
        formType: 'select',
        options: '/SalesSettingStore/select'
      }, {
        prop: 'unit',
        label: '单位',
        width: 100,
        formType: 'input',
        attrs: {
          disabled: true
        },
        validate: {
          required: false,
          max: 20
        }
      }, {
        prop: 'num',
        label: '数量',
        width: 100,
        formType: 'number',
        type: 'number',
        methods: {
          change: (e, row) => this.makeMoney(row)
        },
        validate: {
          pattern: 'intGt0'
        }
      }, {
        prop: 'price',
        label: '单价',
        width: 120,
        formType: 'number',
        type: 'number',
        attrs: {
          append: '元'
        },
        methods: {
          change: (e, row) => this.makeMoney(row)
        },
        validate: {
          min: 0,
          pattern: 'price_6'
        }
      }, {
        prop: 'money',
        label: '金额',
        width: 120,
        formType: 'input',
        attrs: {
          disabled: true,
          append: '元'
        }
      }, {
        prop: 'bill_id',
        label: '票据类型',
        width: 140,
        formType: 'select',
        options: '/InvoiceSetting/select'
      }, {
        label: '备注',
        prop: 'remark',
        width: 200,
        formType: 'input',
        validate: {
          required: false
        }
      }],
      data: [],
      currentIndex: undefined
    };
  },
  methods: {
    getForm() {
      return this.$refs.dialogFormRef.$refs.form;
    },
    async open(row = {}, readonly = false) {
      if (!row || !row.id) {
        this.title = '添加';
        this.action = 'ware/add';
      } else {
        this.title = readonly ? '详情' : '编辑';
        if (row.status > 5) {
          this.action = 'ware/price_save';
        } else {
          this.action = 'ware/edit';
        }
      }
      this.readonly = readonly;
      this.setForm(row);
    },
    async setForm({
      id,
      supplier_id,
      company_id,
      settlement_time,
      bill_id,
      settlement_company_id,
      abstract,
      product = [],
      service_money = 0,
      coupon_money = 0,
      accounts_payable = 0,
      contract_code,
      contract,
      status,
      reason
    }) {
      if (status > 5) {
        ;
        [1, 2, 3, 8, 9].forEach(i => this.$set(this.tableFormColumns[i], 'readonly', true));
      }
      this.form = {
        id: {
          readonly: !id,
          show: false,
          value: id
        },
        settlement_time: {
          readonly: status > 5,
          label: '预结算日期',
          value: settlement_time,
          formType: 'datePicker',
          attrs: {
            pickerOptions: {
              disabledDate: {
                start: 'today'
              }
            },
            style: {
              width: '100% !important'
            }
          }
        },
        company_id: {
          readonly: status > 5,
          label: '合同公司',
          value: company_id,
          formType: 'select',
          options: '/SalesSettingCompany/select?type=0'
        },
        supplier_id: {
          readonly: status > 5,
          label: '供应商',
          value: supplier_id,
          formType: 'select',
          options: '/sales_setting_supplier/select',
          methods: {
            change: async v => {
              const {
                model,
                options
              } = this.getForm();
              options.settlement_company_id = [];
              model.settlement_company_id = '';
              if (!v) return;
              options.settlement_company_id = (await post('/SalesSettingSupplier/settlement', {
                id: v
              })).data.list;
            }
          }
        },
        settlement_company_id: {
          readonly: status > 5,
          label: '结算单位',
          value: settlement_company_id,
          formType: 'select',
          options: supplier_id ? {
            url: '/SalesSettingSupplier/settlement',
            data: {
              id: supplier_id
            }
          } : []
        },
        bill_id: {
          readonly: status > 5,
          label: '票据类型',
          value: bill_id,
          formType: 'select',
          options: '/invoice_setting/select'
        },
        product: {
          width: '100%',
          label: '详情',
          value: JSON.parse(JSON.stringify(product)),
          readonlyValue: JSON.parse(JSON.stringify(product)).map(item => ({
            ...item,
            price: item.price + '元',
            money: item.money + '元'
          })),
          formType: 'productSlot',
          type: 'array'
        },
        amount: {
          readonly: true,
          label: '合计金额',
          value: 0,
          pattern: 'price',
          formType: 'number',
          type: 'number'
        },
        service_money: {
          label: '集成服务费用',
          value: service_money,
          readonlyValue: service_money + '元',
          formType: 'number',
          type: 'number',
          attrs: {
            append: '元'
          },
          methods: {
            change: this.makeAccountsPayable
          }
        },
        coupon_money: {
          label: '优惠金额',
          value: coupon_money,
          readonlyValue: coupon_money + '元',
          pattern: 'price',
          formType: 'number',
          type: 'number',
          attrs: {
            append: '元'
          },
          methods: {
            change: this.makeAccountsPayable
          }
        },
        accounts_payable: {
          label: '应付金额',
          value: accounts_payable,
          readonlyValue: accounts_payable + '元',
          formType: 'number',
          type: 'number',
          attrs: {
            disabled: true,
            append: '元'
          }
        },
        abstract: {
          readonly: status > 5,
          width: '100%',
          label: '采购摘要',
          value: abstract,
          formType: 'textarea',
          required: false
        },
        contract_code: {
          readonly: status > 5,
          value: contract_code,
          label: '合同编码',
          max: 200,
          required: false
        },
        contract: {
          show: model => !!model.contract_code,
          readonly: status > 5,
          label: '合同',
          value: contract,
          formType: 'upload',
          attrs: {
            accept: '.pdf',
            tip: '只能上传 pdf，最大20M',
            limit: 1,
            data: {
              type: 'static'
            }
          }
        },
        reason: {
          show: model => status === 45,
          label: '撤回原因',
          value: reason
        }
      };
      this.$refs.dialogFormRef.visible = true;
      await this.$nextTick();
      this.makeAmount(false);
    },
    makeMoney(row) {
      if (!(row.num > 0) || !(row.price > 0)) return row.money = 0;
      row.money = multiply(row.num, row.price);
      this.makeAmount();
    },
    makeAmount(makeAccountsPayable = true) {
      const {
        model
      } = this.getForm();
      model.amount = model.product.reduce((pre, {
        money
      }) => {
        if (typeof money !== 'number') {
          money = Number(money.replace('元', ''));
        }
        return money ? add(money, pre) : pre;
      }, 0) + '元';
      makeAccountsPayable && this.makeAccountsPayable();
    },
    makeAccountsPayable() {
      const {
        model
      } = this.getForm();
      const service_money = Number(model.service_money);
      const coupon_money = Number(model.coupon_money);
      const amount = Number(model.amount.replace('元', ''));
      if (isNaN(service_money) || isNaN(coupon_money) || isNaN(amount)) return model.accounts_payable = '';
      model.accounts_payable = subtract(add(amount, service_money), coupon_money);
    },
    async beforeSubmit(model) {
      await this.$refs.tableFormRef.validate();
      const product = model.product.map(({
        device_model_id,
        store_id
      }) => JSON.stringify({
        device_model_id,
        store_id
      }));
      if (new Set(product).size !== product.length) {
        this.$message.error('存在相同仓库的相同产品，请合并后提交');
        return Promise.reject();
      }
      !model.contract_code && delete model.contract;
      return model;
    }
  }
};